import {
  takeEvery, all, take
} from 'redux-saga/effects'

import {
  changeLanguage
} from './localizedContent'

import { history } from '../index'
import { getFrontPageCms, getFooterCms } from './cmsContent'
import { getUserInfo, getMyPackages } from './user'
import {
  getInfoCardBlockContent,
  getInfoCardsTitle,
  getCalculatorContent,
  checkBlockFailures, getImage, getBlockTypes
} from './infoCardsContent'
import { getTaskNavigation, setNavigationLevelsByContent } from './taskNavigation'
import { ratuSearch } from './search'

function pushHistory (action) {
  const { route } = action
  history.push(route)
}

function redirectToLogin (action) {
  const { route } = action
  window.location.href = '/login?returnTo=' + route
}

function redirectToError (action) {
  const { errorCode } = action
  window.location.href = '/error/' + errorCode
}

function * changeHistory () {
  while (true) {
    const action = yield take([
      'INFO_CARD_BLOCKS_FAIL_REDIRECT'
    ])
    pushHistory(action)
  }
}

function * redirectUnAuthorized () {
  while (true) {
    const action = yield take([
      'GET_INFO_CARD_BLOCKS_UNAUTHORIZED'
    ])
    redirectToLogin(action)
  }
}

function * redirectForbidden () {
  while (true) {
    const action = yield take([
      'GET_INFO_CARD_BLOCKS_FORBIDDEN'
    ])
    redirectToError(action)
  }
}

function * actionWatcher () {
  yield takeEvery('LANGUAGE_CHANGE_REQUEST', changeLanguage)
  yield takeEvery('FRONTPAGE_CMS_GET_REQUEST', getFrontPageCms)
  yield takeEvery('FOOTER_CMS_GET_REQUEST', getFooterCms)
  yield takeEvery('USER_INFO_GET_REQUEST', getUserInfo)
  yield takeEvery('USER_INFO_GET_SUCCESS', getMyPackages)
  yield takeEvery([
    'GET_INFO_CARD_BLOCK_1_REQUEST',
    'GET_INFO_CARD_BLOCK_2_REQUEST',
    'GET_INFO_CARD_BLOCK_3_REQUEST',
    'GET_INFO_CARD_BLOCK_4_REQUEST']
  , getInfoCardBlockContent)
  yield takeEvery('GET_TASK_NAVI_REQUEST', getTaskNavigation)
  yield takeEvery('GET_INFO_CARD_TITLE_REQUEST', getInfoCardsTitle)
  yield takeEvery(['GET_INFO_CARD_TITLE_SUCCESS', 'GET_TASK_NAVI_SUCCESS'], setNavigationLevelsByContent)
  yield takeEvery('GET_CALCULATOR_CONTENT_REQUEST', getCalculatorContent)
  yield takeEvery([
    'GET_INFO_CARD_BLOCK_1_FAILURE',
    'GET_INFO_CARD_BLOCK_2_FAILURE',
    'GET_INFO_CARD_BLOCK_3_FAILURE',
    'GET_INFO_CARD_BLOCK_4_FAILURE']
  , checkBlockFailures)
  yield takeEvery('GET_IMAGE_REQUEST', getImage)
  yield takeEvery('GET_BLOCK_TYPES_REQUEST', getBlockTypes)
  yield takeEvery('SEARCH_REQUEST', ratuSearch)
}
export function * rootSaga () {
  yield all([
    actionWatcher(),
    changeHistory(),
    redirectUnAuthorized(),
    redirectForbidden()
  ])
}
