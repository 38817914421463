export const getInfoCardBlockContent = (cardReferenceCode, blockId) => ({
  type: `GET_INFO_CARD_BLOCK_${blockId}_REQUEST`,
  blockId,
  cardReferenceCode
})

export const getInfoCardsTitle = (cardReferenceCode) => ({
  type: 'GET_INFO_CARD_TITLE_REQUEST',
  cardReferenceCode
})

export const getCalculatorContent = (cardReferenceCode) => ({
  type: 'GET_CALCULATOR_CONTENT_REQUEST',
  cardReferenceCode
})

export const getImage = (filename) => ({
  type: 'GET_IMAGE_REQUEST',
  filename
})

export const getBlockTypes = () => ({
  type: 'GET_BLOCK_TYPES_REQUEST'
})
