import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import FrontPage from './containers/FrontPage/FrontPage'
import ErrorPage from './containers/ErrorPage/ErrorPage'
import InfoCardsPage from './containers/InfoCards/InfoCardsPage'
import { changeLanguage, getTaskNavigation, getUserInfo } from './actions'

function Routes (props) {
  const localizedContent = useSelector(state => state.localizedContent)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(changeLanguage('fi'))
    dispatch(getUserInfo())
    dispatch(getTaskNavigation())
    document.title = localizedContent.localizedStrings.title
  }, [])

  return (
    <div>
      <Switch>
        <Route exact path='/' component={FrontPage} />
        <Route path='/frontpage' component={FrontPage} />
        <Route path='/content/:cardReferenceCode' component={InfoCardsPage} />
        <Route path='/login*' />
        <Route path='/logout' />
        <Route path='/error/:code?' component={ErrorPage} />
        <Route path='/api/*' />
        <Redirect from='*' to='/' />
      </Switch>
    </div>
  )
}

export default Routes
