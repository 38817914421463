import React from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import { useDispatch, useSelector } from 'react-redux'
import { changeBaseLevelTask, resetSelectedTaskLevels, setTaskNaviActive } from '../../actions'

function FrontPageDisplay (props) {
  const { localizedStrings } = props.localizedContent
  const {
    taskNavigation,
    selectedBaseLvlTaskId,
    whichTaskNaviActive
  } = useSelector(state => state.taskNavigation)
  const { isLoggedIn } = useSelector(state => state.user)
  const dispatch = useDispatch()
  const { frontPageCmsContent, gettingFrontPageCmsContent } = props

  const cmsClasses = 'tile box is-shadowless is-vertical has-background-grey-lightest'
  const cmsClassName = gettingFrontPageCmsContent ? cmsClasses + ' is-loading' : cmsClasses
  const buttonClasses = 'button responsiveButton is-radiusless has-fontweight-500 frontpage-button'

  return (
    <main className='main-content frontpage'>
      <div className='columns is-variable is-8'>
        <div className='column is-three-fifths'>
          {isLoggedIn
            ? (
              <div>
                <h1 className='title is-size-3 is-size-4-touch'>{localizedStrings.ratuFrontpageHeader1}</h1>
                <p className='pb-5 has-fontweight-500 has-text-black frontpageText'>
                  {localizedStrings.ratuFrontpageInfoText0}
                </p>
                <div>
                  {taskNavigation.map((item, index) => {
                    return (
                      <button
                        onClick={() => {
                          dispatch(resetSelectedTaskLevels())
                          dispatch(changeBaseLevelTask(item.headingClassId))
                          whichTaskNaviActive > 0 && dispatch(setTaskNaviActive(1))
                          window.localStorage.setItem('ratuSelectedBaseLvlTaskId', item.headingClassId)
                        }}
                        className={selectedBaseLvlTaskId === item.headingClassId
                          ? buttonClasses +
                        ' frontpage-task-change has-background-rt-blue has-text-white is-selected-task mb-5'
                          : buttonClasses +
                        ' frontpage-task-change has-background-rt-blue-20 has-text-black has-border-rt-blue mb-5'}
                        key={index}
                      >
                        {item.menuDescription}
                        {selectedBaseLvlTaskId === item.headingClassId &&
                          <ion-icon
                            class='has-fontsize-2 has-small-margin-right has-text-white is-selected-task-checkmark'
                            name='checkmark-circle'
                          />}
                      </button>
                    )
                  })}
                </div>
                <button
                  className={buttonClasses + ' is-fullwidth has-background-rt-blue has-text-white change-task-button mb-5'}
                  onClick={() => dispatch(setTaskNaviActive(1))}
                >
                  <ion-icon
                    class='has-fontsize-2 has-small-margin-right has-text-white'
                    name='hammer-outline'
                  />
                  {localizedStrings.chooseTask}
                </button>
                <p className='pb-5 frontpageText'>{localizedStrings.ratuFrontpageInfoText1}</p>
                <p className='pb-5 has-fontweight-500 has-text-black frontpageText'>
                  {localizedStrings.ratuLinkHeader}
                </p>
                <p className='pb-5 frontpageText'>
                  {localizedStrings.ratuLinkDescription1}
                </p>
                <p className='pb-5 frontpageText'>
                  {localizedStrings.ratuLinkDescription2}
                </p>
                <p className='pb-5 frontpageText'>
                  {localizedStrings.ratuLinkDescription3}
                </p>
                <a
                  href='https://ratu.rakennustieto.fi/'
                  className={buttonClasses + ' is-fullwidth has-background-rt-green has-text-white change-task-button mb-5'}
                >
                  <ion-icon
                    class='has-fontsize-2 has-small-margin-right has-text-white'
                    name='hammer-outline'
                  />
                  {localizedStrings.ratuPakkiFrontPageToRatu}
                </a>
              </div>
            )
            : <h1 className='title is-size-3 is-size-4-touch'>{localizedStrings.ratuFrontpageHeader1}</h1>}

          {!isLoggedIn &&
            <div className='pb-5'>
              <p className='pb-5 frontpageText'>{localizedStrings.ratuFrontpageInfoText1}</p>
              <p className='pb-5 frontpageText'>{localizedStrings.ratuFrontpageInfoText3}</p>
              <a
                href='/login'
                className={buttonClasses + ' is-fullwidth has-background-white has-text-rt-blue has-border-rt-blue mb-3'}
              >
                {localizedStrings.logIn}
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={process.env.REACT_APP_RATU_BUY_LINK}
                className={buttonClasses + ' is-fullwidth has-background-rt-blue has-text-white mb-6'}
              >
                {localizedStrings.ratuFrontpageBuy}
              </a>
              <figure className='image is-2by1'>
                <img src='/images/rakennushenkilokuva.jpg' alt={localizedStrings.ratuFrontPageImageAlt} />
              </figure>
            </div>}
        </div>
        <div className='column is-two-fifths'>
          <div className={cmsClassName}>
            <h3 className='title is-5'>{localizedStrings.ratuFrontpageNewsHeader1}</h3>
            {!!Object.keys(frontPageCmsContent).length && frontPageCmsContent.slice(0, 5).map((cmsRow) => {
              return (
                <div key={'cmsRow' + cmsRow.id} className='cms-news-row'>
                  <a href={cmsRow.link} target='_blank' rel='noopener noreferrer'>
                    <h3
                      className='title has-text-link is-6 mb-2'
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cmsRow.title.rendered) }}
                    />
                  </a>
                </div>
              )
            })}
            <a href={process.env.REACT_APP_FRONTPAGE_CMS_DOMAIN} target='_blank' rel='noopener noreferrer'>
              <b>{localizedStrings.ratuFrontPageNewsAll}</b>
            </a>
          </div>
        </div>
      </div>
    </main>
  )
}

FrontPageDisplay.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  frontPageCmsContent: PropTypes.array.isRequired,
  gettingFrontPageCmsContent: PropTypes.bool.isRequired
}

export default FrontPageDisplay
