import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setBurgerMenuActive, setMobileSearchActive, setTaskNaviActive, setUserMenuActive } from '../../actions'

function MenuOpenBackground (props) {
  const { whichTaskNaviActive } = useSelector(state => state.taskNavigation)
  const { isUserMenuActive, isBurgerMenuActive, isMobileSearchActive } = useSelector(state => state.menus)
  const dispatch = useDispatch()

  useEffect(() => {
    // Disables background scrolling
    if (whichTaskNaviActive > 0 ||
      isBurgerMenuActive ||
      isUserMenuActive ||
      isMobileSearchActive) {
      document.querySelector('html').style.overflow = 'hidden'
    } else {
      document.querySelector('html').style.overflow = 'auto'
    }
  })

  return (
    <div
      onClick={() => {
        dispatch(setTaskNaviActive(0))
        dispatch(setBurgerMenuActive(false))
        dispatch(setUserMenuActive(false))
        dispatch(setMobileSearchActive(false))
      }}
      className={(
        whichTaskNaviActive > 0 ||
        isBurgerMenuActive ||
        isUserMenuActive ||
        isMobileSearchActive
      )
        ? 'menu-open-background'
        : 'is-hidden'}
    />
  )
}

export default MenuOpenBackground
