export const changeBaseLevelTask = (taskId) => ({
  type: 'BASE_LVL_TASK_CHANGE',
  taskId
})

export const changeFirstLevelTask = (taskId) => ({
  type: 'FIRST_LVL_TASK_CHANGE',
  taskId
})

export const changeSecondLevelTask = (taskId) => ({
  type: 'SECOND_LVL_TASK_CHANGE',
  taskId
})

export const changeThirdLevelTask = (cardReferenceCode) => ({
  type: 'THIRD_LVL_TASK_CHANGE',
  cardReferenceCode
})

export const resetSelectedTaskLevels = () => ({
  type: 'RESET_TASK_LVLS'
})

export const setTaskNaviActive = (level) => ({
  type: 'SET_TASK_NAVI_ACTIVE',
  level
})

export const getTaskNavigation = () => ({
  type: 'GET_TASK_NAVI_REQUEST'
})
