import React, { useState } from 'react'
import { checkNumber } from '../../utils/utils'

function Calculator (props) {
  const { calculatorContent, blockId } = props
  const [calculatorState, changeCalculatorState] = useState({
    whichCalcOpen: 1
  })
  const [calculatorInputs, changeCalculatorInputs] = useState({})

  const roundToTwoDecimals = (num) => {
    if (isNaN(num)) {
      return 0
    } else if (!isFinite(num)) {
      return '\u221E' // infinity symbol
    } else {
      return Math.round((num + Number.EPSILON) * 100) / 100
    }
  }

  const handleCalculatorState = (e) => {
    e.preventDefault()
    const parsedValue = parseFloat(e.target.value.replace(',', '.').replace(' ', ''))
    const _calculatorInputs = { ...calculatorInputs, [`${e.target.name}Input`]: e.target.value }
    const _calculatorState = { ...calculatorState, [e.target.name]: parsedValue }
    changeCalculatorInputs(_calculatorInputs)
    changeCalculatorState(_calculatorState)
  }

  const handleWhichCalcOpen = (calcId) => {
    const _calculatorState = { ...calculatorState, whichCalcOpen: calcId }
    changeCalculatorState(_calculatorState)
  }

  if (Object.keys(calculatorContent).length) {
    // This is done so you can call these directly and not calculatorState.calc1WorkerPerformance etc...
    const {
      whichCalcOpen,
      calc1WorkerPerformance,
      calc1Amount,
      calc1GroupSize,
      calc2WorkerPerformance,
      calc2Amount,
      calc2WorkDuration
    } = calculatorState

    const {
      calc1WorkerPerformanceInput,
      calc1AmountInput,
      calc1GroupSizeInput,
      calc2WorkerPerformanceInput,
      calc2AmountInput,
      calc2WorkDurationInput
    } = calculatorInputs

    // Calculation happens here
    const calcsWorkerPerformancePlaceholder = ((1 / calculatorContent.consumptionValue.value) * 8)
    const calc1AmountPlaceholder = calculatorContent.amount.value
    const calc1GroupSizePlaceholder = calculatorContent.groupSize.value
    let calc1GroupPerformance
    let calc1Result

    if (checkNumber(calc1WorkerPerformance)) {
      if (checkNumber(calc1GroupSize)) {
        calc1GroupPerformance = calc1WorkerPerformance * calc1GroupSize
      } else {
        calc1GroupPerformance = calc1WorkerPerformance * calc1GroupSizePlaceholder
      }
    } else {
      if (checkNumber(calc1GroupSize)) {
        calc1GroupPerformance = calcsWorkerPerformancePlaceholder * calc1GroupSize
      } else {
        calc1GroupPerformance = calcsWorkerPerformancePlaceholder * calc1GroupSizePlaceholder
      }
    }
    if (checkNumber(calc1Amount)) {
      calc1Result = calc1Amount / calc1GroupPerformance
    } else {
      calc1Result = calc1AmountPlaceholder / calc1GroupPerformance
    }

    const calc2AmountPlaceholder = calculatorContent.amount.value
    const calc2WorkDurationPlaceholder = roundToTwoDecimals(calc1AmountPlaceholder / (calcsWorkerPerformancePlaceholder * calc1GroupSizePlaceholder))
    let calc2GroupPerformance
    let calc2Result

    if (checkNumber(calc2Amount)) {
      if (checkNumber(calc2WorkDuration)) {
        calc2GroupPerformance = calc2Amount / calc2WorkDuration
      } else {
        calc2GroupPerformance = calc2Amount / calc2WorkDurationPlaceholder
      }
    } else {
      if (checkNumber(calc2WorkDuration)) {
        calc2GroupPerformance = calc2AmountPlaceholder / calc2WorkDuration
      } else {
        calc2GroupPerformance = calc2AmountPlaceholder / calc2WorkDurationPlaceholder
      }
    }
    if ((checkNumber(calc2WorkerPerformance)) && (checkNumber(calc2GroupPerformance))) {
      calc2Result = calc2GroupPerformance / calc2WorkerPerformance
    } else if (checkNumber(calc2GroupPerformance)) {
      calc2Result = calc2GroupPerformance / calcsWorkerPerformancePlaceholder
    }
    // Calculation ends here

    return (
      <div key={`block${blockId}calc`} className='is-calculator'>
        <div className='tabs is-boxed is-calculator-tabs'>
          <ul>
            <li className={whichCalcOpen === 1 && 'is-active'}>
              <a onClick={() => handleWhichCalcOpen(1)} className='is-calculator-tab-title'>
                <span>{`${calculatorContent.workDuration.label} (${calculatorContent.workDuration.unit})`}</span>
              </a>
            </li>
            <li className={whichCalcOpen === 2 && 'is-active'}>
              <a onClick={() => handleWhichCalcOpen(2)} className='is-calculator-tab-title'>
                <span>{`${calculatorContent.groupSize.label} (${calculatorContent.groupSize.unit})`}</span>
              </a>
            </li>
          </ul>
        </div>
        {whichCalcOpen === 1
          ? (
            <div className='box is-calculator-rows'>
              <div key={`block${blockId}calc-row-1`} className='is-calculator-row'>
                <p className='is-calculator-text'>{calculatorContent.workerPerformance.label}</p>
                <input
                  name='calc1WorkerPerformance'
                  value={calc1WorkerPerformanceInput}
                  onChange={handleCalculatorState}
                  placeholder={roundToTwoDecimals(calcsWorkerPerformancePlaceholder)}
                  className='is-calculator-input input'
                />
                <p className='is-calculator-unit'>{calculatorContent.workerPerformance.unit}</p>
              </div>
              <div key={`block${blockId}calc-row-2`} className='is-calculator-row'>
                <p className='is-calculator-text'>{calculatorContent.amount.label}</p>
                <input
                  name='calc1Amount'
                  value={calc1AmountInput}
                  onChange={handleCalculatorState}
                  placeholder={calc1AmountPlaceholder}
                  className='is-calculator-input input'
                />
                <p className='is-calculator-unit'>{calculatorContent.amount.unit}</p>
              </div>
              <div key={`block${blockId}calc-row-3`} className='is-calculator-row'>
                <p className='is-calculator-text'>{calculatorContent.groupSize.label}</p>
                <input
                  name='calc1GroupSize'
                  value={calc1GroupSizeInput}
                  onChange={handleCalculatorState}
                  placeholder={calc1GroupSizePlaceholder}
                  className='is-calculator-input input'
                />
                <p className='is-calculator-unit'>{calculatorContent.groupSize.unit}</p>
              </div>
              <div className='is-calculator-row'>
                <p className='is-calculator-text'>{calculatorContent.consumptionValue.label}</p>
                <p className='is-calculator-fixed-amount'>{calculatorContent.consumptionValue.value}</p>
                <p className='is-calculator-unit'>{calculatorContent.consumptionValue.unit}</p>
              </div>
              <div className='is-calculator-row'>
                <p className='is-calculator-text'>{calculatorContent.groupPerformance.label}</p>
                <p className='is-calculator-fixed-amount'>{roundToTwoDecimals(calc1GroupPerformance)}</p>
                <p className='is-calculator-unit'>{calculatorContent.groupPerformance.unit}</p>
              </div>
              <div className='has-calc-border-separator' />
              <div className='is-calculator-row is-calculator-sum-row'>
                <p className='is-calculator-text'>{calculatorContent.workDuration.label}</p>
                <p className='is-calculator-calculated-amount'>{roundToTwoDecimals(calc1Result)}</p>
                <p className='is-calculator-unit'>{calculatorContent.workDuration.unit}</p>
              </div>
            </div>
          )
          : (
            <div className='box is-calculator-rows'>
              <div className='is-calculator-row'>
                <p className='is-calculator-text'>{calculatorContent.workerPerformance.label}</p>
                <input
                  name='calc2WorkerPerformance'
                  value={calc2WorkerPerformanceInput}
                  onChange={handleCalculatorState}
                  placeholder={roundToTwoDecimals(calcsWorkerPerformancePlaceholder)}
                  className='is-calculator-input input'
                />
                <p className='is-calculator-unit'>{calculatorContent.workerPerformance.unit}</p>
              </div>
              <div className='is-calculator-row'>
                <p className='is-calculator-text'>{calculatorContent.amount.label}</p>
                <input
                  name='calc2Amount'
                  value={calc2AmountInput}
                  onChange={handleCalculatorState}
                  placeholder={calc2AmountPlaceholder}
                  className='is-calculator-input input'
                />
                <p className='is-calculator-unit'>{calculatorContent.amount.unit}</p>
              </div>
              <div className='is-calculator-row'>
                <p className='is-calculator-text'>{calculatorContent.workDuration.label}</p>
                <input
                  name='calc2WorkDuration'
                  value={calc2WorkDurationInput}
                  onChange={handleCalculatorState}
                  placeholder={calc2WorkDurationPlaceholder}
                  className='is-calculator-input input'
                />
                <p className='is-calculator-unit'>{calculatorContent.workDuration.unit}</p>
              </div>
              <div className='is-calculator-row'>
                <p className='is-calculator-text'>{calculatorContent.consumptionValue.label}</p>
                <p className='is-calculator-fixed-amount'>{calculatorContent.consumptionValue.value}</p>
                <p className='is-calculator-unit'>{calculatorContent.consumptionValue.unit}</p>
              </div>
              <div className='is-calculator-row'>
                <p className='is-calculator-text'>{calculatorContent.groupPerformance.label}</p>
                <p className='is-calculator-fixed-amount'>{roundToTwoDecimals(calc2GroupPerformance)}</p>
                <p className='is-calculator-unit'>{calculatorContent.groupPerformance.unit}</p>
              </div>
              <div className='has-calc-border-separator' />
              <div className='is-calculator-row is-calculator-sum-row'>
                <p className='is-calculator-text'>{calculatorContent.groupSize.label}</p>
                <p className='is-calculator-calculated-amount'>{roundToTwoDecimals(calc2Result)}</p>
                <p className='is-calculator-unit'>{calculatorContent.groupSize.unit}</p>
              </div>
            </div>
          )}

      </div>
    )
  } else {
    return null
  }
}

export default Calculator
