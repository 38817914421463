import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ErrorDisplay from '../../components/Error/ErrorDisplay'
import { getFooterCms } from '../../actions'
import Header from '../../components/Header/Header'
import HeaderImageWithSearch from '../../components/Header/HeaderImageWithSearch'
import Footer from '../../components/Footer/Footer'
import MenuOpenBackground from '../../components/MenuOpenBackground/MenuOpenBackground'

function ErrorPage (props) {
  const localizedContent = useSelector(state => state.localizedContent)
  const errorCode = props.match.params.code
  const {
    footerCmsContent,
    gettingFooterCmsContent
  } = useSelector(state => state.cmsContent)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getFooterCms())
    document.title = localizedContent.localizedStrings.title
  }, [])

  return (
    <section className='section is-paddingless has-background-grey-lightest'>
      <Header localizedContent={localizedContent} />
      <HeaderImageWithSearch localizedContent={localizedContent} />
      <div className='container has-background-white'>
        <div className='container is-fluid mt-5 mb-4 mb-0-touch'>
          <ErrorDisplay localizedContent={localizedContent} errorCode={errorCode} />
        </div>
      </div>
      <Footer
        footerCmsContent={footerCmsContent}
        gettingFooterCmsContent={gettingFooterCmsContent}
      />
      <MenuOpenBackground />
    </section>
  )
}

export default ErrorPage
