export const setUserMenuActive = (isActive) => ({
  type: 'SET_USER_MENU_ACTIVE',
  isActive
})

export const setBurgerMenuActive = (isActive) => ({
  type: 'SET_BURGER_MENU_ACTIVE',
  isActive
})

export const setMobileSearchActive = (isActive) => ({
  type: 'SET_MOBILE_SEARCH_ACTIVE',
  isActive
})
