import React from 'react'

function ErrorDisplay (props) {
  const { localizedStrings } = props.localizedContent
  const { errorCode } = props

  return (
    <main className='main-content errorpage'>
      <div className='columns is-variable is-8'>
        <div className='column is-full'>
          <h2 className='title is-size-3 is-size-4-touch'>
            {parseInt(errorCode) === 404
              ? localizedStrings.error404Header
              : parseInt(errorCode) === 403
                ? localizedStrings.error403Header
                : localizedStrings.errorOtherHeader}
          </h2>
          {(parseInt(errorCode) === 403) &&
            <p className='pb-5 errorpageText'>
              {localizedStrings.errorMessage403}
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={process.env.REACT_APP_RATU_BUY_LINK}
              > {localizedStrings.errorFromHere}
              </a>
            </p>}
          <p className='pb-5 errorpageText'>
            {localizedStrings.errorMessage1}
            <a href={`mailto:${process.env.REACT_APP_RAKTIE_MAILTO}`}>{` ${process.env.REACT_APP_RAKTIE_MAILTO}`}</a>
          </p>
          <p className='pb-5 errorpageText'>
            {localizedStrings.errorMessage2}
            <a href='/'> {localizedStrings.errorFromHere}</a>
          </p>
          <p className='pb-5 errorpageText'>
            {localizedStrings.errorMessage3}
            <a href={process.env.REACT_APP_RAKTIE_URL}> {localizedStrings.errorFromHere}</a>
          </p>
        </div>
      </div>
    </main>
  )
}

export default ErrorDisplay
