export const ratuSearch = (searchString) => {
  if (searchString && searchString.length > 0) {
    return {
      type: 'SEARCH_REQUEST',
      searchString
    }
  } else {
    return {
      type: 'SEARCH_EMPTY'
    }
  }
}

export const switchSearchActive = (isSearchActive) => ({
  type: 'SWITCH_SEARCH_ACTIVE',
  isSearchActive
})

export const setSearching = () => ({
  type: 'SET_SEARCH_SEARCHING'
})
