import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

function UserMenu (props) {
  const { isLoggedIn, userInfo, myPackages } = useSelector(state => state.user)
  const { isUserMenuActive, setUserMenuActive, localizedStrings } = props
  const [isMyServicesOpen, setMyServicesOpen] = useState(false)
  const dispatch = useDispatch()

  const handleChangeUser = () => {
    // Because SSO server does not support real logout call app logout and open login page with direct link so we'll
    // lose the app linkage for login
    window.fetch('/logout')
      .then(() => {
        window.location.href = process.env.REACT_APP_LOGIN_URL
      })
  }

  return (
    <div className={isUserMenuActive
      ? 'menu-is-fixed-right has-background-white'
      : 'menu-is-fixed-right has-background-white menu-hide-right'}
    >
      {isLoggedIn &&
        <aside className='menu'>
          <div
            className='button menu-close'
            onClick={() => dispatch(setUserMenuActive(!isUserMenuActive))}
          >
            <ion-icon class='has-fontsize-2' name='close-outline' />
          </div>
          <div className='menu-user-details'>
            <p className='user-name'>
              {userInfo.name}
            </p>
            <p className='company-name'>
              {userInfo.customerNames[userInfo.customerIds[0]]}
            </p>
          </div>
          <ul className='menu-list'>
            <li />
            <li>
              <a onClick={() => setMyServicesOpen(!isMyServicesOpen)}>
                <ion-icon class='has-fontsize-2 has-small-margin-right' name='lock-open-outline' />
                {localizedStrings.myServices}
                <ion-icon
                  class={isMyServicesOpen
                    ? 'user-menu-dropdown-arrow rotate-180 md hydrated'
                    : 'user-menu-dropdown-arrow md hydrated'}
                  name='chevron-down-outline'
                />
              </a>
              <ul className={isMyServicesOpen ? 'user-menu-dropdown' : 'user-menu-dropdown is-hidden'}>
                {(Array.isArray(myPackages) && myPackages.length) &&
                  myPackages.map((myPackage) => {
                    if (myPackage.visibilityUserFrontpage.toLowerCase() === 'visible' ||
                      myPackage.visibilityUserFrontpage.toLowerCase() === 'public') {
                      return (
                        <li key={myPackage.id}><a href={myPackage.landingPage}>{myPackage.name}</a></li>
                      )
                    }
                  })}
              </ul>
            </li>
            <li>
              <a href={process.env.REACT_APP_MY_FOLDERS_LINK}>
                <ion-icon class='has-fontsize-2 has-small-margin-right' name='folder-open-outline' />
                {localizedStrings.myFolders}
              </a>
            </li>
            <li>
              <a href={process.env.REACT_APP_ACCOUNT_INFO_LINK}>
                <ion-icon class='has-fontsize-2 has-small-margin-right' name='information-circle-outline' />
                {localizedStrings.accountInfo}
              </a>
            </li>
            <li>
              <a onClick={handleChangeUser}>
                <ion-icon class='has-fontsize-2 has-small-margin-right rotate-180' name='log-in-outline' />
                {localizedStrings.changeUser}
              </a>
            </li>
            <li>
              <a href='/logout'>
                <ion-icon class='has-fontsize-2 has-small-margin-right' name='log-out-outline' />
                {localizedStrings.logOut}
              </a>
            </li>
          </ul>
        </aside>}
    </div>
  )
}

UserMenu.propTypes = {
  isUserMenuActive: PropTypes.bool.isRequired,
  setUserMenuActive: PropTypes.func.isRequired,
  localizedStrings: PropTypes.object.isRequired
}

export default UserMenu
