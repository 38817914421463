import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

function BurgerMenu (props) {
  const { isBurgerMenuActive, setBurgerMenuActive, localizedStrings } = props
  const [isLangDropdownOpen, setLangDropdownOpen] = useState(false)
  const dispatch = useDispatch()
  return (
    <div className={isBurgerMenuActive
      ? 'burger-menu-is-fixed-left has-background-white'
      : 'burger-menu-is-fixed-left has-background-white menu-hide-left'}
    >
      <aside className='menu'>
        <div
          className='button menu-close burger-menu-close'
          onClick={() => dispatch(setBurgerMenuActive(!isBurgerMenuActive))}
        >
          <ion-icon class='has-fontsize-2' name='close-outline' />
        </div>
        <Link to='/'>
          <img className='burger-menu-logo' src='/images/rakennustieto_logo_330px_no_margin.png' />
        </Link>
        <ul className='menu-list'>
          <li />
          <li>
            <a>
              RT tietoväylä
            </a>
            <ul className='burger-menu-sublist'>
              <li><a>Kortistot</a></li>
              <li><a>RYL-palvelu</a></li>
              <li><a className='is-active'>{localizedStrings.ratuPakki}</a></li>
              <li><a>MRL-palvelu</a></li>
              <li><a>RTS-ympäristöluokitus</a></li>
              <li><a>Verkkokauppa</a></li>
            </ul>
          </li>
          <li className={isLangDropdownOpen
            ? 'burger-lang-menu is-open is-hidden-desktop is-hidden'
            : 'burger-lang-menu is-hidden-desktop is-hidden'}
          >
            <li className='menu-label lang-header'>
              <p>Kieli / Language / Språk / Keel</p>
            </li>
            <a onClick={() => setLangDropdownOpen(!isLangDropdownOpen)}>
              <ion-icon class='has-fontsize-2 has-small-margin-right' name='globe-outline' />
              {localizedStrings.currentLang}
              <ion-icon
                class={isLangDropdownOpen
                  ? 'burger-menu-dropdown-arrow rotate-180 md hydrated'
                  : 'burger-menu-dropdown-arrow md hydrated'}
                name='chevron-down-outline'
              />
            </a>
            <ul className={isLangDropdownOpen ? 'burger-menu-sublist' : 'burger-menu-sublist is-hidden'}>
              <li><a>English</a></li>
              <li><a>Svenska</a></li>
            </ul>
          </li>
        </ul>
      </aside>
    </div>
  )
}

BurgerMenu.propTypes = {
  isUserMenuActive: PropTypes.bool.isRequired,
  setUserMenuActive: PropTypes.func.isRequired,
  localizedStrings: PropTypes.object.isRequired
}

export default BurgerMenu
