import { switchSearchActive } from '../actions'

export const checkNumber = (num) => {
  return num && isFinite(num)
}

export const handleSearchBlur = (e, dispatch) => {
  const currentTarget = e.currentTarget
  // Check the newly focused element in the next tick of the event loop
  setTimeout(() => {
    // Check if the new activeElement is a child of the original container
    if (!currentTarget.contains(document.activeElement)) {
      dispatch(switchSearchActive(false))
    }
  }, 0)
}
