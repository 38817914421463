const infoCardsContent = (state = {
  block1Content: {},
  block2Content: {},
  block3Content: {},
  block4Content: {},
  gettingBlock1Content: false,
  gettingBlock2Content: false,
  gettingBlock3Content: false,
  gettingBlock4Content: false,
  infoCardsTitle: {},
  gettingInfoCardsTitle: false,
  calculatorContent: {},
  block1Fail: false,
  block2Fail: false,
  block3Fail: false,
  block4Fail: false,
  images: {},
  blockTypes: {}
}, action) => {
  switch (action.type) {
    case 'GET_INFO_CARD_BLOCK_1_REQUEST':
      return { ...state, gettingBlock1Content: true }

    case 'GET_INFO_CARD_BLOCK_1_SUCCESS':
      return { ...state, block1Content: action.blockData, gettingBlock1Content: false, block1Fail: false }

    case 'GET_INFO_CARD_BLOCK_1_FAILURE':
      return { ...state, gettingBlock1Content: false, block1Fail: true }

    case 'GET_INFO_CARD_BLOCK_2_REQUEST':
      return { ...state, gettingBlock2Content: true }

    case 'GET_INFO_CARD_BLOCK_2_SUCCESS':
      return { ...state, block2Content: action.blockData, gettingBlock2Content: false, block2Fail: false }

    case 'GET_INFO_CARD_BLOCK_2_FAILURE':
      return { ...state, gettingBlock2Content: false, block2Fail: true }

    case 'GET_INFO_CARD_BLOCK_3_REQUEST':
      return { ...state, gettingBlock3Content: true }

    case 'GET_INFO_CARD_BLOCK_3_SUCCESS':
      return { ...state, block3Content: action.blockData, gettingBlock3Content: false, block3Fail: false }

    case 'GET_INFO_CARD_BLOCK_3_FAILURE':
      return { ...state, gettingBlock3Content: false, block3Fail: true }

    case 'GET_INFO_CARD_BLOCK_4_REQUEST':
      return { ...state, gettingBlock4Content: true }

    case 'GET_INFO_CARD_BLOCK_4_SUCCESS':
      return { ...state, block4Content: action.blockData, gettingBlock4Content: false, block4Fail: false }

    case 'GET_INFO_CARD_BLOCK_4_FAILURE':
      return { ...state, gettingBlock4Content: false, block4Fail: true }

    case 'GET_INFO_CARD_BLOCKS_UNAUTHORIZED':
      return {
        ...state,
        gettingBlock1Content: false,
        gettingBlock2Content: false,
        gettingBlock3Content: false,
        gettingBlock4Content: false
      }

    case 'GET_INFO_CARD_BLOCKS_FORBIDDEN':
      return {
        ...state,
        gettingBlock1Content: false,
        gettingBlock2Content: false,
        gettingBlock3Content: false,
        gettingBlock4Content: false
      }

    case 'GET_INFO_CARD_TITLE_REQUEST':
      return { ...state, gettingInfoCardsTitle: true }

    case 'GET_INFO_CARD_TITLE_SUCCESS':
      return { ...state, infoCardsTitle: action.infoCardsTitle, gettingInfoCardsTitle: false }

    case 'GET_INFO_CARD_TITLE_FAILURE':
      return { ...state, gettingInfoCardsTitle: false }

    case 'GET_INFO_CARD_TITLE_UNAUTHORIZED':
      return { ...state, gettingInfoCardsTitle: false }

    case 'GET_CALCULATOR_CONTENT_REQUEST':
      return { ...state }

    case 'GET_CALCULATOR_CONTENT_SUCCESS':
      return { ...state, calculatorContent: action.calculatorContent }

    case 'GET_CALCULATOR_CONTENT_FAILURE':
      return { ...state }

    case 'GET_CALCULATOR_CONTENT_UNAUTHORIZED':
      return { ...state }

    case 'GET_IMAGE_REQUEST':
      return { ...state, images: { ...state.images, [`isGetting${action.filename}`]: true } }

    case 'GET_IMAGE_SUCCESS':
      return {
        ...state,
        images: {
          ...state.images,
          [action.filename]: action.image,
          [`isGetting${action.filename}`]: false,
          [`hasTriedGetting${action.filename}`]: true
        }
      }

    case 'GET_IMAGE_FAILURE':
      return {
        ...state,
        images: {
          ...state.images,
          [`isGetting${action.filename}`]: false,
          [`hasTriedGetting${action.filename}`]: true
        }
      }

    case 'GET_IMAGE_UNAUTHORIZED':
      return {
        ...state,
        images: {
          ...state.images,
          [`isGetting${action.filename}`]: false,
          [`hasTriedGetting${action.filename}`]: true
        }
      }

    case 'GET_BLOCK_TYPES_REQUEST':
      return { ...state }

    case 'GET_BLOCK_TYPES_SUCCESS':
      return { ...state, blockTypes: action.blockTypes }

    case 'GET_BLOCK_TYPES_FAILURE':
      return { ...state }

    case 'GET_BLOCK_TYPES_UNAUTHORIZED':
      return { ...state }

    default:
      return state
  }
}
export default infoCardsContent
