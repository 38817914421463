import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ratuSearch, setMobileSearchActive, switchSearchActive } from '../../actions'

function SearchResults (props) {
  const { inputString, setSearchString } = props
  const { isSearching, searchResults, isSearchActive, searchString } = useSelector(state => state.search)
  const { localizedStrings } = useSelector(state => state.localizedContent)
  const dispatch = useDispatch()

  const searchStringToBold = (resultLabel) => {
    const lowerCaseLabel = resultLabel.toLowerCase()
    const lowerCaseSearch = searchString.toLowerCase()
    const searchStringIndex = lowerCaseLabel.indexOf(lowerCaseSearch)
    const searchStringLength = searchString.length

    if (searchStringIndex > -1) {
      return (
        resultLabel.substr(0, searchStringIndex) +
        '<b>' + resultLabel.substr(searchStringIndex, searchStringLength) + '</b>' +
        resultLabel.substr(searchStringIndex + searchStringLength)
      )
    } else {
      return resultLabel
    }
  }

  return (
    <div>
      {!!(isSearchActive && inputString) &&
        <div className='searchResults'>
          <ul>
            <a
              tabIndex='0'
              target='_blank'
              rel='noopener noreferrer'
              href={`${process.env.REACT_APP_RAKTIE_SEARCH}${inputString}`}
            >
              <li className='searchResult staticSearch'>
                {`${localizedStrings.search} "${inputString}" ${localizedStrings.fromCardIndex} >`}
              </li>
            </a>
            {(!isSearching && Array.isArray(searchResults) && searchResults.length)
              ? (
                <div>
                  {searchResults.map(result => {
                    return (
                      <Link
                        tabindex='0'
                        key={`searchResult${result.cardReferenceCode}`}
                        to={`/content/${result.cardReferenceCode}`}
                        onClick={() => {
                          dispatch(ratuSearch(''))
                          dispatch(switchSearchActive(false))
                          setSearchString('')
                          dispatch(setMobileSearchActive(false))
                        }}
                      >
                        <li className='searchResult' dangerouslySetInnerHTML={{ __html: searchStringToBold(result.label) }} />
                      </Link>
                    )
                  })}
                </div>
              )
              : (
                isSearching
                  ? <li className='is-loading searchSearching searchResult' />
                  : <div />
              )}
          </ul>
        </div>}
    </div>

  )
}

export default SearchResults
