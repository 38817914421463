import {
  put, select
} from 'redux-saga/effects'

import * as selectors from '../selectors'

export function * changeLanguage (action) {
  try {
    const localizedContent = yield select(selectors.localizedContent)
    const localizedStrings = localizedContent.localizedStrings
    if (localizedStrings.getLanguage() !== action.lang &&
            localizedStrings.getAvailableLanguages().includes(action.lang)) {
      localizedStrings.setLanguage(action.lang)
      yield put({
        type: 'LANGUAGE_CHANGE_SUCCESS',
        localizedStrings: localizedStrings,
        currentLang: action.lang
      })
    } else {
      yield put({ type: 'LANGUAGE_CHANGE_FAILURE', output: 'not valid/already set' })
    }
  } catch (e) {
    yield put({ type: 'LANGUAGE_CHANGE_FAILURE', output: e })
  }
}
