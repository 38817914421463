import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeBaseLevelTask,
  changeFirstLevelTask,
  changeSecondLevelTask,
  resetSelectedTaskLevels,
  setTaskNaviActive
} from '../../actions'
import { Link } from 'react-router-dom'

function TaskNavigation (props) {
  const {
    taskNavigation,
    localizedStrings
  } = props
  const {
    selectedBaseLvlTaskId,
    selectedFirstLvlTaskId,
    selectedSecondLvlTaskId,
    selectedThirdLvlTaskReferenceCode,
    whichTaskNaviActive
  } = useSelector(state => state.taskNavigation)
  const dispatch = useDispatch()

  const selectedBaseLvlTask = taskNavigation.find(item => item.headingClassId === selectedBaseLvlTaskId)
  let selectedFirstLvlTask
  let selectedSecondLvlTask

  if (selectedBaseLvlTask) {
    selectedFirstLvlTask = selectedBaseLvlTask.headings.find(item => item.id === selectedFirstLvlTaskId)
  }
  if (selectedFirstLvlTask) {
    selectedSecondLvlTask = selectedFirstLvlTask.childHeadings.find(item => item.id === selectedSecondLvlTaskId)
  }

  return (
    <div className={'task-navigation task-navi-active-' + whichTaskNaviActive}>
      {selectedBaseLvlTask &&
        <div
          className={'navbar is-fixed-top menu-fixed-left-header header-size-' + whichTaskNaviActive}
          role='navigation'
          aria-label='navigation'
        >
          <p className='navbar-item is-uppercase'>{selectedBaseLvlTask.menuDescription}</p>
          <a
            onClick={() => {
              dispatch(setTaskNaviActive(1))
              dispatch(resetSelectedTaskLevels())
              dispatch(changeBaseLevelTask(
                selectedBaseLvlTaskId === parseInt(process.env.REACT_APP_TALO_2000_ID)
                  ? parseInt(process.env.REACT_APP_TALO_80_ID)
                  : parseInt(process.env.REACT_APP_TALO_2000_ID)
              ))
            }}
            className='navbar-item change-title-link'
          >{localizedStrings.changeTaskTitle}
          </a>
          <div
            className='button menu-close task-navi-close'
            onClick={() => dispatch(setTaskNaviActive(0))}
          >
            <ion-icon class='has-fontsize-2' name='close' />
          </div>
        </div>}

      <div className={whichTaskNaviActive >= 1
        ? 'menu-is-fixed-left left-first has-background-white'
        : 'menu-is-fixed-left left-first has-background-white menu-hide-left'}
      >
        <aside className='menu'>
          <ul className='menu-list'>
            {selectedBaseLvlTask &&

                selectedBaseLvlTask.headings.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        selectedFirstLvlTaskId === item.id
                          ? 'task-navi-list-item is-first-selected'
                          : 'task-navi-list-item'
                      }
                    >
                      <a
                        onClick={() => {
                          dispatch(setTaskNaviActive(2))
                          dispatch(changeFirstLevelTask(item.id))
                        }}
                        className='has-60px-padding-right'
                      >
                        {item.menuDescription}
                        <ion-icon
                          class='task-navi-forward-arrow has-small-margin-right'
                          name='chevron-forward-outline'
                        />
                      </a>
                    </li>
                  )
                })}
          </ul>
        </aside>
      </div>

      <div className={whichTaskNaviActive >= 2
        ? 'menu-is-fixed-left left-second has-background-white'
        : 'menu-is-fixed-left left-second has-background-white menu-hide-left'}
      >
        <aside className='menu'>
          <ul className='menu-list'>
            <li className='task-navi-list-item is-hidden-desktop'>
              <a onClick={() => dispatch(setTaskNaviActive(1))}>
                <ion-icon class='task-navi-backwards-arrow has-small-margin-right' name='chevron-back-outline' />
                {localizedStrings.goToMainMenu}
              </a>
            </li>
            {selectedFirstLvlTask &&
              <div>
                <li className='task-navi-list-header'>
                  <p>{selectedFirstLvlTask.menuDescription}</p>
                </li>
                {selectedFirstLvlTask.childHeadings.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={selectedSecondLvlTaskId === item.id
                        ? 'task-navi-list-item is-second-selected'
                        : 'task-navi-list-item'}
                    >
                      <a
                        onClick={() => {
                          dispatch(setTaskNaviActive(3))
                          dispatch(changeSecondLevelTask(item.id))
                        }}
                        className='has-60px-padding-right'
                      >
                        {item.menuDescription}
                        <ion-icon class='task-navi-forward-arrow has-small-margin-right' name='chevron-forward-outline' />
                      </a>
                    </li>
                  )
                })}
              </div>}
          </ul>
        </aside>
      </div>

      <div className={whichTaskNaviActive >= 3
        ? 'menu-is-fixed-left left-third has-background-white'
        : 'menu-is-fixed-left left-third has-background-white menu-hide-left'}
      >
        <aside className='menu'>
          <ul className='menu-list'>
            <li className='task-navi-list-item is-hidden-desktop'>
              <a onClick={() => {
                dispatch(setTaskNaviActive(2))
                setTimeout(() => { dispatch(setTaskNaviActive(1)) }, 250)
              }}
              >
                <ion-icon class='task-navi-backwards-arrow has-small-margin-right' name='chevron-back-outline' />
                {localizedStrings.goToMainMenu}
              </a>
            </li>
            {selectedSecondLvlTask &&
              <div>
                <li className='task-navi-list-item is-hidden-desktop'>
                  <a onClick={() => dispatch(setTaskNaviActive(2))}>
                    <ion-icon class='task-navi-backwards-arrow has-small-margin-right' name='chevron-back-outline' />
                    {selectedFirstLvlTask.menuDescription}
                  </a>
                </li>
                <li className='task-navi-list-header'>
                  <p>
                    {selectedSecondLvlTask.menuDescription}
                  </p>
                </li>
                {selectedSecondLvlTask.cards.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={selectedThirdLvlTaskReferenceCode === item.cardReferenceCode
                        ? 'task-navi-list-item is-third-selected'
                        : 'task-navi-list-item'}
                    >
                      <Link
                        to={'/content/' + item.cardReferenceCode}
                        onClick={() => {
                          dispatch(setTaskNaviActive(0))
                          window.localStorage.setItem('ratuSelectedBaseLvlTaskId', selectedBaseLvlTaskId)
                        }}
                      >
                        {item.label}
                      </Link>
                    </li>
                  )
                })}
              </div>}
          </ul>
        </aside>
      </div>
    </div>

  )
}

TaskNavigation.propTypes = {
  localizedStrings: PropTypes.object.isRequired,
  taskNavigation: PropTypes.array.isRequired
}

export default TaskNavigation
