import React from 'react'
import PropTypes from 'prop-types'
import {
  changeBaseLevelTask,
  changeFirstLevelTask,
  changeSecondLevelTask,
  changeThirdLevelTask,
  setTaskNaviActive
} from '../../actions'
import { useDispatch, useSelector } from 'react-redux'
import InfoCard from './InfoCard'

function InfoCardsDisplay (props) {
  const { localizedStrings } = props.localizedContent
  const { infoCardsContent } = props
  const {
    openedBaseLvlTaskId,
    openedFirstLvlTaskId,
    openedSecondLvlTaskId,
    openedThirdLvlTaskReferenceCode
  } = useSelector(state => state.taskNavigation)
  const dispatch = useDispatch()
  const buttonClasses = 'button mb-5 responsiveButton is-radiusless has-fontweight-500'

  const blockConsumptionId = parseInt(process.env.REACT_APP_BLOCK_CONSUMPTION_ID)
  const blockProcedureId = parseInt(process.env.REACT_APP_BLOCK_PROCEDURE_ID)
  const blockSafetyId = parseInt(process.env.REACT_APP_BLOCK_SAFETY_ID)
  const blockQualityId = parseInt(process.env.REACT_APP_BLOCK_QUALITY_ID)

  const handlePrint = () => {
    window.print()
  }

  return (
    <main key='main-content' className='main-content'>
      {(!infoCardsContent.gettingInfoCardsTitle &&
        infoCardsContent.infoCardsTitle.label &&
        infoCardsContent.infoCardsTitle.label !== '')
        ? <h1 className='title infocardTitle is-size-3 is-size-4-mobile'>{infoCardsContent.infoCardsTitle.label}</h1>
        : <h1 className='title infocardTitle is-size-3 is-size-4-mobile'>‎</h1> /* Invisible character */}
      {!!(!infoCardsContent.gettingInfoCardsTitle &&
        infoCardsContent.infoCardsTitle.cardReferenceCode &&
        infoCardsContent.infoCardsTitle.cardReferenceCode !== '') &&
          <p className='infocardReferenceCode'>{infoCardsContent.infoCardsTitle.cardReferenceCode}</p>}
      <button className='button print-button hidden-print' onClick={handlePrint}>
        <ion-icon class='has-fontsize-2' name='print' />
      </button>
      <button
        className={
          buttonClasses +
        ' has-background-white has-text-rt-blue change-task-button-content-page' +
        ' has-border-rt-blue is-hidden-touch hidden-print'
        }
        onClick={() => {
          dispatch(changeBaseLevelTask(openedBaseLvlTaskId))
          dispatch(changeFirstLevelTask(openedFirstLvlTaskId))
          dispatch(changeSecondLevelTask(openedSecondLvlTaskId))
          dispatch(changeThirdLevelTask(openedThirdLvlTaskReferenceCode))
          dispatch(setTaskNaviActive(3))
        }}
      >
        <ion-icon
          class='has-fontsize-2 has-small-margin-right has-text-rt-blue'
          name='hammer'
        />
        {localizedStrings.chooseTask}
      </button>
      <div key='blockColumns' className='columns is-fullhd is-multiline'>
        <div key='block1column' className='column is-half-fullhd'>
          <div key='block1message' className='message has-background-white'>
            <div className='message-header has-background-rt-blue'>
              {Object.keys(infoCardsContent.blockTypes).length
                ? (infoCardsContent.blockTypes.map(block => {
                  if (block.blockTypeId === blockConsumptionId) {
                    return block.blockTypeLabel
                  }
                }))
                : (
                  <div />
                )}
            </div>
            <InfoCard
              key='block1'
              blockContent={infoCardsContent[`block${blockConsumptionId}Content`]}
              blockId={blockConsumptionId}
              infoCardsContent={infoCardsContent}
              localizedStrings={localizedStrings}
            />
          </div>
        </div>
        <div key='block4column' className='column is-half-fullhd'>
          <div key='block4message' className='message has-background-white'>
            <div className='message-header has-background-rt-blue'>
              {Object.keys(infoCardsContent.blockTypes).length
                ? (infoCardsContent.blockTypes.map(block => {
                  if (block.blockTypeId === blockProcedureId) {
                    return block.blockTypeLabel
                  }
                }))
                : (
                  <div />
                )}
            </div>
            <InfoCard
              key='block4'
              blockContent={infoCardsContent[`block${blockProcedureId}Content`]}
              blockId={blockProcedureId}
              infoCardsContent={infoCardsContent}
              localizedStrings={localizedStrings}
            />
          </div>
        </div>
        <div key='block3column' className='column is-half-fullhd'>
          <div key='block3message' className='message has-background-white'>
            <div className='message-header has-background-rt-blue'>
              {Object.keys(infoCardsContent.blockTypes).length
                ? (infoCardsContent.blockTypes.map(block => {
                  if (block.blockTypeId === blockSafetyId) {
                    return block.blockTypeLabel
                  }
                }))
                : (
                  <div />
                )}
            </div>
            <InfoCard
              key='block3'
              blockContent={infoCardsContent[`block${blockSafetyId}Content`]}
              blockId={blockSafetyId}
              infoCardsContent={infoCardsContent}
              localizedStrings={localizedStrings}
            />
          </div>
        </div>
        <div key='block2column' className='column is-half-fullhd'>
          <div key='block2message' className='message has-background-white'>
            <div className='message-header has-background-rt-blue'>
              {Object.keys(infoCardsContent.blockTypes).length
                ? (infoCardsContent.blockTypes.map(block => {
                  if (block.blockTypeId === blockQualityId) {
                    return block.blockTypeLabel
                  }
                }))
                : (
                  <div />
                )}
            </div>
            <InfoCard
              key='block2'
              blockContent={infoCardsContent[`block${blockQualityId}Content`]}
              blockId={blockQualityId}
              infoCardsContent={infoCardsContent}
              localizedStrings={localizedStrings}
            />
          </div>
        </div>
      </div>
      <div className='tile box has-rt-blue-border-left static-links
      is-shadowless has-background-rt-lighter-blue hidden-print'
      >
        <a target='_blank' rel='noopener noreferrer' href={process.env.REACT_APP_STATIC_LINK_1}>
          <span className='icon'><ion-icon name='open-outline' /></span><b>{localizedStrings.staticLinkText1}</b>
        </a>
        <a target='_blank' rel='noopener noreferrer' href={process.env.REACT_APP_STATIC_LINK_2}>
          <span className='icon'><ion-icon name='open-outline' /></span>{localizedStrings.staticLinkText2}
        </a>
        <a target='_blank' rel='noopener noreferrer' href={process.env.REACT_APP_STATIC_LINK_3}>
          <span className='icon'><ion-icon name='open-outline' /></span>{localizedStrings.staticLinkText3}
        </a>
        <a target='_blank' rel='noopener noreferrer' href={process.env.REACT_APP_STATIC_LINK_4}>
          <span className='icon'><ion-icon name='open-outline' /></span>{localizedStrings.staticLinkText4}
        </a>
        <a target='_blank' rel='noopener noreferrer' href={process.env.REACT_APP_STATIC_LINK_5}>
          <span className='icon'><ion-icon name='open-outline' /></span>{localizedStrings.staticLinkText5}
        </a>
      </div>
    </main>
  )
}

InfoCardsDisplay.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  infoCardsTitle: PropTypes.string,
  infoCardsContent: PropTypes.object.isRequired
}

export default InfoCardsDisplay
