const user = (state = {
  isLoggedIn: false,
  userInfo: {},
  myPackages: []
}, action) => {
  switch (action.type) {
    case 'USER_INFO_GET_REQUEST':
      return { ...state }

    case 'USER_INFO_GET_SUCCESS':
      return { ...state, userInfo: action.userInfo, isLoggedIn: true }

    case 'USER_INFO_GET_FAILURE':
      return { ...state, userInfo: {}, isLoggedIn: false }

    case 'MY_PACKAGES_GET_SUCCESS':
      return { ...state, myPackages: action.myPackages }

    case 'MY_PACKAGES_GET_FAILURE':
      return { ...state, myPackages: {} }

    default:
      return state
  }
}
export default user
