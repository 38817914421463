import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import UserMenu from '../Navigation/UserMenu'
import TaskNavigation from '../Navigation/TaskNavigation'
import BurgerMenu from '../Navigation/BurgerMenu'
import {
  setTaskNaviActive,
  setUserMenuActive,
  setBurgerMenuActive,
  setMobileSearchActive,
  ratuSearch,
  switchSearchActive,
  setSearching
} from '../../actions'
import { handleSearchBlur } from '../../utils/utils'

import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SearchResults from '../Search/SearchResults'

function Header (props) {
  const { localizedStrings } = props.localizedContent
  const { isLoggedIn, userInfo } = useSelector(state => state.user)
  const { taskNavigation } = useSelector(state => state.taskNavigation)
  const { isUserMenuActive, isBurgerMenuActive, isMobileSearchActive } = useSelector(state => state.menus)

  const dispatch = useDispatch()
  const [mobileSearchString, setMobileSearchString] = useState('')
  const ifMobileSearchOpenNavClass = isMobileSearchActive ? ' mobile-search-open' : ''
  const changeSearchDropdown = () => {
    dispatch(setMobileSearchActive(!isMobileSearchActive))
  }

  const handleSearch = (searchString) => {
    setMobileSearchString(searchString)
  }

  useEffect(() => {
    dispatch(setSearching())
    const timer = setTimeout(() => {
      dispatch(ratuSearch(mobileSearchString))
    }, 500)
    return () => clearTimeout(timer)
  }, [mobileSearchString])

  return (
    <div className='hidden-print'>
      <nav
        className={'navbar is-fixed-top is-block' + ifMobileSearchOpenNavClass}
        role='navigation'
        aria-label='main navigation'
      >
        <div className='is-flex'>
          <div className='navbar-brand'>
            <Link className='navbar-item header-logo ml-4' to='/'>
              <img src='/images/Rakennustieto_logo_nega_CMYK.png' />
            </Link>
          </div>
          <div className='navbar-menu'>
            <div className='navbar-end has-dynamic-margin-right'>
              <div className='navbar-item has-dropdown is-hoverable is-hidden-touch is-hidden'>
                <a className='navbar-link'>
                  <span className='icon has-small-margin-right'><ion-icon name='globe' /></span>
                  <p>{localizedStrings.currentLang}</p>
                </a>

                <div className='navbar-dropdown'>
                  <a className='navbar-item'>
                    English
                  </a>
                  <a className='navbar-item'>
                    Svenska
                  </a>
                </div>
              </div>
              <div className='navbar-item has-dropdown is-hidden-desktop has-position-static'>
                <a className={isMobileSearchActive ? 'has-background-rt-darker-blue navbar-item' : 'navbar-item'} onClick={changeSearchDropdown}>
                  {isMobileSearchActive
                    ? (
                      <span className='icon'><ion-icon name='close-sharp' /></span>
                    )
                    : (
                      <span className='icon'><ion-icon name='search-sharp' /></span>
                    )}
                </a>

                <div className={(isMobileSearchActive ? 'is-block' : '') + ' navbar-dropdown is-radiusless'}>
                  <div
                    className='navbar-item'
                    onBlur={(e) => handleSearchBlur(e, dispatch)}
                  >
                    <input
                      key='mobileSearchInput'
                      value={mobileSearchString}
                      onChange={(e) => handleSearch(e.target.value)}
                      className='input mobile-search-input is-radiusless'
                      placeholder={localizedStrings.searchPlaceholder}
                      onFocus={() => dispatch(switchSearchActive(true))}
                    />
                    <SearchResults inputString={mobileSearchString} setSearchString={setMobileSearchString} />
                  </div>
                </div>
              </div>
              {isLoggedIn
                ? (
                  <div className='navbar-item has-dropdown'>
                    <a
                      className='navbar-item is-hidden-touch'
                      onClick={() => {
                        dispatch(setUserMenuActive(!isUserMenuActive))
                        dispatch(setTaskNaviActive(0))
                        dispatch(setBurgerMenuActive(false))
                      }}
                    >
                      <span className='icon has-small-margin-right'><ion-icon name='person-sharp' /></span>
                      <p className='is-hidden-touch'>{userInfo.name}</p>
                    </a>
                    <a
                      className='navbar-item is-hidden-desktop'
                      onClick={() => {
                        dispatch(setUserMenuActive(!isUserMenuActive))
                        dispatch(setTaskNaviActive(0))
                        dispatch(setBurgerMenuActive(false))
                      }}
                    >
                      <span className='icon has-small-margin-right'><ion-icon name='person-sharp' /></span>
                      <p className='is-hidden-touch'>{userInfo.name}</p>
                    </a>

                    <div className='navbar-dropdown is-right'>
                      <a className='navbar-item'>
                        {localizedStrings.accountInfo}
                      </a>
                      <a className='navbar-item'>
                        {localizedStrings.changeUser}
                      </a>
                      <a className='navbar-item'>
                        {localizedStrings.logOut}
                      </a>
                    </div>
                  </div>
                )
                : (
                  <a href='/login' className='navbar-item'>
                    <span className='icon has-small-margin-right'><ion-icon name='person-sharp' /></span>
                    <p className='is-hidden-touch'>{localizedStrings.logIn}</p>
                  </a>
                )}
            </div>
          </div>
        </div>
      </nav>
      <div onClick={() => {
        dispatch(setTaskNaviActive(0))
        dispatch(setUserMenuActive(false))
      }}
      >
        <BurgerMenu
          isBurgerMenuActive={isBurgerMenuActive}
          setBurgerMenuActive={setBurgerMenuActive}
          localizedStrings={localizedStrings}
        />
      </div>
      {!!(Array.isArray(taskNavigation) && taskNavigation.length) &&
        <div onClick={() => {
          dispatch(setUserMenuActive(false))
          dispatch(setBurgerMenuActive(false))
        }}
        >
          <TaskNavigation
            taskNavigation={taskNavigation}
            localizedStrings={localizedStrings}
          />
        </div>}
      <div onClick={() => {
        dispatch(setTaskNaviActive(0))
        dispatch(setBurgerMenuActive(false))
      }}
      >
        <UserMenu
          isUserMenuActive={isUserMenuActive}
          setUserMenuActive={setUserMenuActive}
          localizedStrings={localizedStrings}
        />
      </div>
    </div>
  )
}

Header.propTypes = {
  localizedContent: PropTypes.object.isRequired
}

export default Header
