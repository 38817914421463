const menus = (state = {
  isUserMenuActive: false,
  isBurgerMenuActive: false,
  isMobileSearchActive: false
}, action) => {
  switch (action.type) {
    case 'SET_USER_MENU_ACTIVE':
      return { ...state, isUserMenuActive: action.isActive }

    case 'SET_BURGER_MENU_ACTIVE':
      return { ...state, isBurgerMenuActive: action.isActive }

    case 'SET_MOBILE_SEARCH_ACTIVE':
      return { ...state, isMobileSearchActive: action.isActive }

    default:
      return state
  }
}
export default menus
