import React from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'

function Footer (props) {
  const { footerCmsContent, gettingFooterCmsContent } = props
  return (
    <footer className='footer has-background-rt-blue hidden-print'>
      {(Object.keys(footerCmsContent).length && !gettingFooterCmsContent) &&
        <div
          className='content has-content-justified is-flex'
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(footerCmsContent.content.rendered) }}
        />}
    </footer>
  )
}

Footer.propTypes = {
  footerCmsContent: PropTypes.object.isRequired,
  gettingFooterCmsContent: PropTypes.bool.isRequired
}

export default Footer
