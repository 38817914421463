const search = (state = {
  isSearching: false,
  searchResults: [],
  isSearchActive: false,
  searchString: ''
}, action) => {
  switch (action.type) {
    case 'SEARCH_REQUEST':
      return { ...state, searchString: action.searchString, isSearching: true }

    case 'SEARCH_SUCCESS':
      return { ...state, isSearching: false, searchResults: action.searchResults }

    case 'SEARCH_FAILURE':
      return { ...state, isSearching: false }

    case 'SEARCH_EMPTY':
      return { ...state, searchString: '' }

    case 'SWITCH_SEARCH_ACTIVE':
      return { ...state, isSearchActive: action.isSearchActive }

    case 'SET_SEARCH_SEARCHING':
      return { ...state, isSearching: true }

    default:
      return state
  }
}
export default search
