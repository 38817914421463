import {
  put, call
} from 'redux-saga/effects'

export function * getUserInfo (action) {
  const Url = '/api/cxf/user/myauthorizations'
  try {
    const response = yield call(window.fetch, Url, {
      method: 'GET',
      headers: new window.Headers({
        'Content-Type': 'application/json'
      })
    })
    if (response.ok) {
      const jsonResponse = yield response.json()

      if (jsonResponse.name && jsonResponse.name.includes('null')) {
        jsonResponse.name = jsonResponse.userName
      }

      yield put({ type: 'USER_INFO_GET_SUCCESS', userInfo: jsonResponse })
    } else {
      const jsonResponse = yield response.body
      yield put({ type: 'USER_INFO_GET_FAILURE', output: jsonResponse })
    }
  } catch (e) {
    yield put({ type: 'USER_INFO_GET_FAILURE', output: e })
  }
}

export function * getMyPackages () {
  const Url = '/api/cxf/juha/content/mypackages'
  try {
    const response = yield call(window.fetch, Url, {
      method: 'GET',
      headers: new window.Headers({
        'Content-Type': 'application/json'
      })
    })
    if (response.ok) {
      const jsonResponse = yield response.json()
      yield put({ type: 'MY_PACKAGES_GET_SUCCESS', myPackages: jsonResponse })
    } else {
      const jsonResponse = yield response.body
      yield put({ type: 'MY_PACKAGES_GET_FAILURE', output: jsonResponse })
    }
  } catch (e) {
    yield put({ type: 'MY_PACKAGES_GET_FAILURE', output: e })
  }
}
