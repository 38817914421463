const taskNavigation = (state = {
  selectedBaseLvlTaskId: parseInt(window.localStorage.getItem('ratuSelectedBaseLvlTaskId'))
    ? parseInt(window.localStorage.getItem('ratuSelectedBaseLvlTaskId'))
    : parseInt(process.env.REACT_APP_TALO_2000_ID),
  selectedFirstLvlTaskId: null,
  selectedSecondLvlTaskId: null,
  selectedThirdLvlTaskReferenceCode: null,
  openedBaseLvlTaskId: null,
  openedFirstLvlTaskId: null,
  openedSecondLvlTaskId: null,
  openedThirdLvlTaskReferenceCode: null,
  whichTaskNaviActive: 0,
  taskNavigation: []
}, action) => {
  switch (action.type) {
    case 'BASE_LVL_TASK_CHANGE':
      return { ...state, selectedBaseLvlTaskId: action.taskId }

    case 'FIRST_LVL_TASK_CHANGE':
      return { ...state, selectedFirstLvlTaskId: action.taskId }

    case 'SECOND_LVL_TASK_CHANGE':
      return { ...state, selectedSecondLvlTaskId: action.taskId }

    case 'THIRD_LVL_TASK_CHANGE':
      return { ...state, selectedThirdLvlTaskReferenceCode: action.cardReferenceCode }

    case 'RESET_TASK_LVLS':
      return { ...state, selectedBaseLvlTaskId: 0, selectedFirstLvlTaskId: 0, selectedSecondLvlTaskId: 0 }

    case 'SET_TASK_NAVI_ACTIVE':
      return { ...state, whichTaskNaviActive: action.level }

    case 'GET_TASK_NAVI_REQUEST':
      return { ...state }

    case 'GET_TASK_NAVI_SUCCESS':
      return { ...state, taskNavigation: action.taskNavigation }

    case 'GET_TASK_NAVI_UNAUTHORIZED':
      return { ...state }

    case 'GET_TASK_NAVI_FAILURE':
      return { ...state }

    case 'NAVIGATION_LEVELS_SET':
      return {
        ...state,
        openedBaseLvlTaskId: action.levels.openedBaseLvlTaskId,
        openedFirstLvlTaskId: action.levels.openedFirstLvlTaskId,
        openedSecondLvlTaskId: action.levels.openedSecondLvlTaskId,
        openedThirdLvlTaskReferenceCode: action.levels.openedThirdLvlTaskReferenceCode
      }

    default:
      return state
  }
}
export default taskNavigation
