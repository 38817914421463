import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeBaseLevelTask,
  changeFirstLevelTask,
  changeSecondLevelTask, changeThirdLevelTask,
  setTaskNaviActive
} from '../../actions'

function Breadcrumbs (props) {
  const { localizedStrings } = props.localizedContent

  const {
    openedBaseLvlTaskId,
    openedFirstLvlTaskId,
    openedSecondLvlTaskId,
    openedThirdLvlTaskReferenceCode,
    taskNavigation
  } = useSelector(state => state.taskNavigation)
  const dispatch = useDispatch()

  const location = useLocation()
  const isContentPage = location.pathname.includes('/content/')
  const isSearchPage = location.pathname.includes('/search/')
  const CrumbSeparator = () => <p className='crumb-separator'>{'>'}</p>
  const FrontPageCrumb = () => <Link className='ratu-breadcrumb' to='/'>{localizedStrings.ratuPakki}</Link>

  const ContentPageCrumbs = () => {
    const selectedBaseLvlTask = taskNavigation.find(item => item.headingClassId === openedBaseLvlTaskId)
    let selectedFirstLvlTask, selectedSecondLvlTask

    if (selectedBaseLvlTask) {
      selectedFirstLvlTask = selectedBaseLvlTask.headings.find(item => item.id === openedFirstLvlTaskId)
    }
    if (selectedFirstLvlTask) {
      selectedSecondLvlTask = selectedFirstLvlTask.childHeadings.find(item => item.id === openedSecondLvlTaskId)
    }

    return (
      <div>
        {(selectedBaseLvlTask && selectedFirstLvlTask && selectedSecondLvlTask) &&
          <div className='is-flex columns breadcrumb-columns'>
            <div
              className='column is-flex breadcrumb-burger-column'
              onClick={() => {
                dispatch(changeBaseLevelTask(openedBaseLvlTaskId))
                dispatch(changeFirstLevelTask(openedFirstLvlTaskId))
                dispatch(changeSecondLevelTask(openedSecondLvlTaskId))
                dispatch(changeThirdLevelTask(openedThirdLvlTaskReferenceCode))
                dispatch(setTaskNaviActive(3))
              }}
            >
              <ion-icon class='has-fontsize-2' name='menu-sharp' />
            </div>
            <div className='column is-flex'>
              <FrontPageCrumb />
              <CrumbSeparator />
              <p
                className='ratu-breadcrumb' onClick={() => {
                  dispatch(changeBaseLevelTask(openedBaseLvlTaskId))
                  dispatch(setTaskNaviActive(1))
                }}
              >{selectedBaseLvlTask.menuDescription}
              </p>
              <CrumbSeparator />
              <p
                className='ratu-breadcrumb' onClick={() => {
                  dispatch(changeBaseLevelTask(openedBaseLvlTaskId))
                  dispatch(changeFirstLevelTask(openedFirstLvlTaskId))
                  dispatch(setTaskNaviActive(2))
                }}
              >{selectedFirstLvlTask.menuDescription}
              </p>
              <CrumbSeparator />
              <p
                className='ratu-breadcrumb' onClick={() => {
                  dispatch(changeBaseLevelTask(openedBaseLvlTaskId))
                  dispatch(changeFirstLevelTask(openedFirstLvlTaskId))
                  dispatch(changeSecondLevelTask(openedSecondLvlTaskId))
                  dispatch(setTaskNaviActive(3))
                }}
              >{selectedSecondLvlTask.menuDescription}
              </p>
            </div>
          </div>}
      </div>
    )
  }

  return (
    <div className='breadcrumbs columns is-centered has-margin-top-0 has-background-grey-breadcrumbs'>
      <div className={isContentPage ? 'column is-11' : 'column'}>
        <div className='container is-fluid'>
          {isContentPage &&
            <ContentPageCrumbs />}
          {isSearchPage &&
            <div className='is-flex'>
              <FrontPageCrumb />
              <CrumbSeparator />
              <p className='ratu-breadcrumb'>TODO</p>
            </div>}
        </div>
      </div>
    </div>
  )
}

export default Breadcrumbs
