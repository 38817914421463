import LocalizedStrings from 'react-localization'
import localizedStrings from '../data/localizedStrings.json'

const localizedContent = (state = {
  localizedStrings: new LocalizedStrings(localizedStrings),
  currentLang: 'en'
}, action) => {
  switch (action.type) {
    case 'LANGUAGE_CHANGE_REQUEST':
      return { ...state }

    case 'LANGUAGE_CHANGE_SUCCESS':
      return {
        ...state,
        localizedStrings: action.localizedStrings,
        currentLang: action.currentLang
      }

    case 'LANGUAGE_CHANGE_FAILURE':
      return { ...state }

    default:
      return state
  }
}
export default localizedContent
