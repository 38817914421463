import {
  call,
  put, select
} from 'redux-saga/effects'
import * as selectors from '../selectors'

export function * getTaskNavigation (action) {
  const apiUrlStart = '/api/cxf/ratu-infocards-content/navigation/'

  const talo2000url = apiUrlStart + process.env.REACT_APP_TALO_2000_ID
  const talo80url = apiUrlStart + process.env.REACT_APP_TALO_80_ID

  const init = {
    method: 'GET',
    headers: new window.Headers({
      'Content-Type': 'application/json'
    })
  }

  try {
    const talo2000Response = yield call(window.fetch, talo2000url, init)
    const talo80Response = yield call(window.fetch, talo80url, init)

    if (talo2000Response.ok && talo80Response.ok) {
      const talo2000Navigation = yield talo2000Response.json()
      const talo80Navigation = yield talo80Response.json()

      if (talo2000Navigation.languageId === 'FI') {
        talo2000Navigation.menuDescription = 'Talo 2000'
      }
      if (talo80Navigation.languageId === 'FI') {
        talo80Navigation.menuDescription = 'Talo 80'
      }

      yield put({
        type: 'GET_TASK_NAVI_SUCCESS',
        taskNavigation: [talo2000Navigation, talo80Navigation]
      })
    } else {
      if (talo2000Response.status === 401 || talo80Response.status === 401) {
        yield put({
          type: 'GET_TASK_NAVI_UNAUTHORIZED',
          output: { talo2000Response, talo80Response }
        })
      } else {
        yield put({
          type: 'GET_TASK_NAVI_FAILURE',
          output: { talo2000Response, talo80Response }
        })
      }
    }
  } catch (e) {
    yield put({ type: 'GET_TASK_NAVI_FAILURE', output: e })
  }
}

export function * setNavigationLevelsByContent (action) {
  const infoCardsContent = yield select(selectors.infoCardsContent)
  const taskNavigation = yield select(selectors.taskNavigation)
  const localStorageBaseLvlId = window.localStorage.getItem('ratuSelectedBaseLvlTaskId')

  if (Object.keys(infoCardsContent.infoCardsTitle).length &&
    Array.isArray(taskNavigation.taskNavigation) &&
    taskNavigation.taskNavigation.length) {
    let navigationBaseLvl
    let navigationFirstLvl
    let navigationSecondLvl

    if (localStorageBaseLvlId) {
      navigationBaseLvl = taskNavigation.taskNavigation.find((baseLvl) => {
        return baseLvl.headingClassId === parseInt(localStorageBaseLvlId)
      })
      navigationFirstLvl = navigationBaseLvl.headings.find((firstLvl) => {
        navigationSecondLvl = firstLvl.childHeadings.find((secondLvl) => {
          const found = secondLvl.cards.find(thirdLvl => thirdLvl.cardReferenceCode === infoCardsContent.infoCardsTitle.cardReferenceCode)
          if (found) { return secondLvl }
        })
        if (navigationSecondLvl) { return firstLvl }
      })
    }

    if (!navigationBaseLvl || !navigationFirstLvl || !navigationSecondLvl) {
      navigationBaseLvl = taskNavigation.taskNavigation.find((baseLvl) => {
        navigationFirstLvl = baseLvl.headings.find((firstLvl) => {
          navigationSecondLvl = firstLvl.childHeadings.find((secondLvl) => {
            const found = secondLvl.cards.find(thirdLvl => thirdLvl.cardReferenceCode === infoCardsContent.infoCardsTitle.cardReferenceCode)
            if (found) { return secondLvl }
          })
          if (navigationSecondLvl) { return firstLvl }
        })
        if (navigationFirstLvl) { return baseLvl }
      })
    }

    if (navigationBaseLvl && navigationFirstLvl && navigationSecondLvl) {
      yield put({
        type: 'NAVIGATION_LEVELS_SET',
        levels: {
          openedBaseLvlTaskId: navigationBaseLvl.headingClassId,
          openedFirstLvlTaskId: navigationFirstLvl.id,
          openedSecondLvlTaskId: navigationSecondLvl.id,
          openedThirdLvlTaskReferenceCode: infoCardsContent.infoCardsTitle.cardReferenceCode
        }
      })
    }
  }
}
