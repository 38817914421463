const cmsContent = (state = {
  gettingFrontPageCmsContent: false,
  frontPageCmsContent: [],
  gettingFooterCmsContent: false,
  footerCmsContent: {}
}, action) => {
  switch (action.type) {
    // FRONTPAGE CMS GET
    case 'FRONTPAGE_CMS_GET_REQUEST':
      return { ...state, gettingFrontPageCmsContent: true }

    case 'FRONTPAGE_CMS_GET_SUCCESS':
      return { ...state, frontPageCmsContent: action.frontPageCmsContent, gettingFrontPageCmsContent: false }

    case 'FRONTPAGE_CMS_GET_FAILURE':
      return { ...state, output: action.output, gettingFrontPageCmsContent: false }

    // FOOTER CMS GET
    case 'FOOTER_CMS_GET_REQUEST':
      return { ...state, gettingFooterCmsContent: true }

    case 'FOOTER_CMS_GET_SUCCESS':
      return { ...state, footerCmsContent: action.footerCmsContent, gettingFooterCmsContent: false }

    case 'FOOTER_CMS_GET_FAILURE':
      return { ...state, output: action.output, gettingFooterCmsContent: false }

    default:
      return state
  }
}
export default cmsContent
