import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import localizedContent from './localizedContent'
import cmsContent from './cmsContent'
import taskNavigation from './taskNavigation'
import user from './user'
import infoCardsContent from './infoCardsContent'
import menus from './menus'
import search from './search'

export default history => combineReducers({
  router: connectRouter(history),
  localizedContent,
  cmsContent,
  taskNavigation,
  user,
  infoCardsContent,
  menus,
  search
})
