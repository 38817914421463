import React from 'react'
import ReactTooltip from 'react-tooltip'

import Calculator from './Calculator'
import { getImage } from '../../actions'
import { useDispatch, useSelector } from 'react-redux'

function InfoCard (props) {
  const {
    images
  } = useSelector(state => state.infoCardsContent)
  const { blockContent, blockId, infoCardsContent, localizedStrings } = props
  const { calculatorContent } = infoCardsContent
  const dispatch = useDispatch()

  const blockConsumptionId = parseInt(process.env.REACT_APP_BLOCK_CONSUMPTION_ID)
  const blockProcedureId = parseInt(process.env.REACT_APP_BLOCK_PROCEDURE_ID)

  const sortedInfoCardTexts = (textData) => {
    return textData.sort((a, b) => {
      return a.rowSorter - b.rowSorter
    })
  }

  const sortedConsumptionRows = (consumptionRows) => {
    return consumptionRows.sort((a, b) => {
      return a.rowSorter - b.rowSorter
    })
  }

  const TextRows = () => {
    if (blockContent.textData !== 404) {
      return sortedInfoCardTexts(blockContent.textData).map((item, index) => {
        if (item.rowType === 1) {
          return (
            <h3 className='title is-6'>{item.text}</h3>
          )
        } else if (item.rowType === 0) {
          return <li><p>{item.text}</p></li>
        } else {
          return null
        }
      })
    } else {
      return null
    }
  }

  return (
    <div key={`block${blockId}message-div`}>
      {(Object.keys(blockContent).length && !infoCardsContent[`gettingBlock${blockId}Content`])
        ? (
          <div
            key={`block${blockId}message-body`}
            className='message-body content is-infoCardContent has-rt-light-blue-border-top'
          >
            <div key={`block${blockId}message-body-columns`} className='columns is-multiline'>
              <div className={(parseInt(blockId) === blockConsumptionId &&
                  'calculator-columns') + ' columns is-multiline is-full-fullhd is-9 is-marginless has-100-width'}
              >
                {parseInt(blockId) !== blockConsumptionId &&
                  <div
                    key={`block${blockId}message-body-column`}
                    className={blockContent.imageData !== 404
                      // Text is fulllength if block has no images
                      ? 'column is-6 is-9-fullhd infoCardText'
                      : 'column is-9 is-full-fullhd infoCardText'}
                  >
                    {parseInt(blockId) === blockProcedureId
                      // Block id 4 is only quarter with ordered list
                      ? (
                        <ol key={`${blockId}ol`}>
                          <TextRows
                            key={`${blockId}textRows`}
                          />
                        </ol>
                      ) : (
                        <ul key={`${blockId}ul`}>
                          <TextRows
                            key={`${blockId}textRows`}
                          />
                        </ul>
                      )}
                  </div>}
                {parseInt(blockId) === blockConsumptionId &&
                  <div className='column is-7 is-full-touch has-no-padding-right-desktop'>
                    <h3 className='title is-size-6 is-calculator-heading'>{localizedStrings.calculate}</h3>
                    <Calculator key={`${blockId}calculator`} calculatorContent={calculatorContent} blockId={blockId} />
                  </div>}
                {parseInt(blockId) === blockConsumptionId &&
                  <div className='column is-5 is-full-touch consumption-div'>
                    <h3 className='title is-size-6'>{localizedStrings.ratuConsumption}</h3>
                    {Object.keys(calculatorContent).length &&
                      <div className='is-relative'>
                        {!!(Array.isArray(calculatorContent.consumptionRows) && calculatorContent.consumptionRows.length) &&
                          <div className='box is-shadowless has-background-grey-lightest consumption-rows'>
                            {sortedConsumptionRows(calculatorContent.consumptionRows).map((row) => {
                              return (
                                <div key={`consumptionRow${row.id}`} className='consumption-row'>
                                  <p className='consumption-text'>{row.label}</p>
                                  <p className='consumption-amount'>{row.value}</p>
                                  <p className={(row.unit === '%' ? 'percentage' : '') + ' consumption-unit'}>
                                    {row.unit}
                                  </p>
                                </div>
                              )
                            })}
                          </div>}
                      </div>}
                    <div
                      key={`block${blockId}message-body-column`}
                      className='column is-full infoCardText is-consumption-infotext'
                    >
                      <ul key={`${blockId}ul`}>
                        <TextRows
                          key={`${blockId}textRows`}
                        />
                      </ul>
                    </div>
                  </div>}
                {(parseInt(blockId) !== blockConsumptionId && blockContent.imageData !== 404) &&
                  <div className='column is-3 has-0-75em-padding'>
                    <div className='has-text-centered'>
                      {blockContent.imageData.map((item) => {
                        if (!images[item.filename] &&
                        !images[`isGetting${item.filename}`] &&
                        !images[`hasTriedGetting${item.filename}`]) {
                          dispatch(getImage(item.filename))
                          return null
                        } else if (!images[item.filename] &&
                        images[`isGetting${item.filename}`] &&
                        !images[`hasTriedGetting${item.filename}`]) {
                          return <div className='is-loading'><img alt={item.imageLabel} src='/images/image-outline.svg' /></div>
                        } else {
                          return (
                            <div>
                              <img alt={item.imageLabel} src={images[item.filename]} data-tip data-for={`toolTipForImage${item.id}`} />
                              {item.imageLabel &&
                                <ReactTooltip
                                  className='imageToolTip'
                                  id={`toolTipForImage${item.id}`}
                                  place='bottom'
                                  type='info'
                                  effect='solid'
                                  offset={{ top: 15 }}
                                >
                                  <span>{item.imageLabel}</span>
                                </ReactTooltip>}
                            </div>
                          )
                        }
                      })}
                    </div>
                  </div>}
                {parseInt(blockId) !== blockConsumptionId &&
                  <div className='column has-0-75em-padding has-link-border is-full-fullhd hidden-print'>
                    <div className='columns is-multiline is-mobile link-columns'>
                      {blockContent.linkData !== 404 && blockContent.linkData.map((item, index) => {
                        return (
                          <a
                            key={`block${blockId}link${index}`}
                            className='column is-full-infocard-link is-link has-text-link'
                            href={item.url}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <span className='icon'><ion-icon name='open-outline' /></span>{item.linkLabel}
                          </a>
                        )
                      })}
                    </div>
                  </div>}
              </div>
              {parseInt(blockId) === blockConsumptionId &&
                // Block id for consumption has a different responsive layout
                <div className='column has-0-75em-padding has-link-border is-full-fullhd hidden-print'>
                  <div className='columns is-multiline is-mobile link-columns'>
                    {blockContent.linkData !== 404 && blockContent.linkData.map((item, index) => {
                      return (
                        <a
                          key={`block${blockId}link${index}`}
                          className='column is-full-infocard-link is-link has-text-link'
                          href={item.url}
                        >
                          <span className='icon'><ion-icon name='open-outline' /></span>{item.linkLabel}
                        </a>
                      )
                    })}
                  </div>
                </div>}
            </div>
          </div>
        )
        : (
          <div>
            {!infoCardsContent[`gettingBlock${blockId}Content`]
              ? <div className='message-body content is-infoCardContent-loading has-rt-light-blue-border-top' />
              : (
                <div
                  className='message-body content is-infoCardContent-loading is-loading has-rt-light-blue-border-top'
                />
              )}
          </div>
        )}
    </div>
  )
}

export default InfoCard
