import React, { useState, useEffect } from 'react'
import {
  changeBaseLevelTask,
  changeFirstLevelTask,
  changeSecondLevelTask,
  changeThirdLevelTask,
  ratuSearch,
  setTaskNaviActive,
  switchSearchActive,
  setSearching
} from '../../actions'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import SearchResults from '../Search/SearchResults'
import { handleSearchBlur } from '../../utils/utils'

function HeaderImageWithSearch (props) {
  const { localizedStrings } = props.localizedContent
  const {
    openedBaseLvlTaskId,
    openedFirstLvlTaskId,
    openedSecondLvlTaskId,
    openedThirdLvlTaskReferenceCode
  } = useSelector(state => state.taskNavigation)
  const dispatch = useDispatch()
  const location = useLocation()
  const isContentPage = location.pathname.includes('/content/')

  const [searchString, setSearchString] = useState('')

  const handleSearch = (searchString) => {
    setSearchString(searchString)
  }

  useEffect(() => {
    dispatch(setSearching())
    const timer = setTimeout(() => {
      dispatch(ratuSearch(searchString))
    }, 500)
    return () => clearTimeout(timer)
  }, [searchString])

  const buttonClasses = 'button mb-5 responsiveButton is-radiusless has-fontweight-500'
  return (
    <div className='hidden-print'>
      <div className='is-hidden-desktop is-sticky-mobile-header'>
        <h1 className='title is-4 is-sticky-mobile-header-title'>{localizedStrings.ratuPakki}</h1>
        {isContentPage &&
          <button
            className={
              buttonClasses +
            ' has-background-white has-text-rt-blue change-task-button-mobile-header has-border-rt-blue'
            }
            onClick={() => {
              dispatch(changeBaseLevelTask(openedBaseLvlTaskId))
              dispatch(changeFirstLevelTask(openedFirstLvlTaskId))
              dispatch(changeSecondLevelTask(openedSecondLvlTaskId))
              dispatch(changeThirdLevelTask(openedThirdLvlTaskReferenceCode))
              dispatch(setTaskNaviActive(3))
            }}
          >
            <ion-icon
              class='has-fontsize-2 has-small-margin-right has-text-rt-blue is-hidden-under-400'
              name='hammer'
            />
            {localizedStrings.chooseTask}
          </button>}
      </div>
      <div
        className='is-hidden-touch is-desktop-search-header'
      >
        <div className='columns is-centered'>
          <div className='column is-11'>
            <div className='container is-fluid'>
              <h1 className='title is-3 is-desktop-search-header-title'>{localizedStrings.ratuPakki}</h1>
              <div className='header-search-div'>
                <div
                  className='header-search-relative-div'
                  onBlur={(e) => handleSearchBlur(e, dispatch)}
                >
                  <input
                    key='headerSearchInput'
                    value={searchString}
                    placeholder={localizedStrings.searchPlaceholder}
                    onChange={(e) => handleSearch(e.target.value)}
                    className='header-search-input is-radiusless'
                    onFocus={() => dispatch(switchSearchActive(true))}
                  />
                  <SearchResults inputString={searchString} setSearchString={setSearchString} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderImageWithSearch
