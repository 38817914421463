import {
  put, call
} from 'redux-saga/effects'

export function * getFrontPageCms (action) {
  const Url = `${process.env.REACT_APP_FRONTPAGE_CMS_DOMAIN}/wp-json/wp/v2/posts?categories=${process.env.REACT_APP_CMS_CATEGORY}`
  try {
    const response = yield call(window.fetch, Url, {
      method: 'GET',
      headers: new window.Headers({
        'Content-Type': 'application/json'
      })
    })
    if (response.ok) {
      const jsonResponse = yield response.json()
      yield put({ type: 'FRONTPAGE_CMS_GET_SUCCESS', frontPageCmsContent: jsonResponse })
    } else {
      const jsonResponse = yield response.body
      yield put({ type: 'FRONTPAGE_CMS_GET_FAILURE', output: jsonResponse })
    }
  } catch (e) {
    yield put({ type: 'FRONTPAGE_CMS_GET_FAILURE', output: e })
  }
}

export function * getFooterCms (action) {
  const Url = `${process.env.REACT_APP_FOOTER_CMS_DOMAIN}/wp-json/wp/v2/pages/${process.env.REACT_APP_CMS_FOOTER}`
  try {
    const response = yield call(window.fetch, Url, {
      method: 'GET',
      headers: new window.Headers({
        'Content-Type': 'application/json'
      })
    })
    if (response.ok) {
      const jsonResponse = yield response.json()
      yield put({ type: 'FOOTER_CMS_GET_SUCCESS', footerCmsContent: jsonResponse })
    } else {
      const jsonResponse = yield response.body
      yield put({ type: 'FOOTER_CMS_GET_FAILURE', output: jsonResponse })
    }
  } catch (e) {
    yield put({ type: 'FOOTER_CMS_GET_FAILURE', output: e })
  }
}
