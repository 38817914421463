import {
  call,
  put, select
} from 'redux-saga/effects'

import * as selectors from '../selectors'

export function * getInfoCardBlockContent (action) {
  const locationPath = window.location.pathname
  const apiUrlStart = '/api/cxf/ratu-infocards-content'
  const apiUrlEnd = `card/${action.cardReferenceCode}/block/${action.blockId}`
  const textUrl = `${apiUrlStart}/text/${apiUrlEnd}`
  const imageUrl = `${apiUrlStart}/image/${apiUrlEnd}`
  const linkUrl = `${apiUrlStart}/link/${apiUrlEnd}`
  const init = {
    method: 'GET',
    headers: new window.Headers({
      'Content-Type': 'application/json'
    })
  }
  try {
    const textResponse = yield call(window.fetch, textUrl, init)
    const imageResponse = yield call(window.fetch, imageUrl, init)
    const linkResponse = yield call(window.fetch, linkUrl, init)

    if (textResponse.ok && imageResponse.ok && linkResponse.ok) {
      const textData = yield textResponse.json()
      const imageData = yield imageResponse.json()
      const linkData = yield linkResponse.json()

      yield put({
        type: `GET_INFO_CARD_BLOCK_${action.blockId}_SUCCESS`,
        blockData: { textData, imageData, linkData }
      })
    } else {
      if (textResponse.status === 401 || imageResponse.status === 401 || linkResponse.status === 401) {
        yield put({
          type: 'GET_INFO_CARD_BLOCKS_UNAUTHORIZED',
          route: locationPath,
          output: { textResponse, imageResponse, linkResponse }
        })
      } else if (textResponse.status === 403 || imageResponse.status === 403 || linkResponse.status === 403) {
        yield put({
          type: 'GET_INFO_CARD_BLOCKS_FORBIDDEN',
          errorCode: '403',
          output: { textResponse, imageResponse, linkResponse }
        })
      } else if (textResponse.status === 404 || imageResponse.status === 404 || linkResponse.status === 404) {
        let textData = 404
        if (textResponse.status !== 404) {
          textData = yield textResponse.json()
        }
        let imageData = 404
        if (imageResponse.status !== 404) {
          imageData = yield imageResponse.json()
        }
        let linkData = 404
        if (linkResponse.status !== 404) {
          linkData = yield linkResponse.json()
        }
        if (textData === 404 && imageData === 404 && linkData === 404) {
          yield put({
            type: `GET_INFO_CARD_BLOCK_${action.blockId}_FAILURE`,
            output: { textResponse, imageResponse, linkResponse }
          })
        } else {
          yield put({
            type: `GET_INFO_CARD_BLOCK_${action.blockId}_SUCCESS`,
            blockData: { textData, imageData, linkData }
          })
        }
      } else {
        yield put({
          type: `GET_INFO_CARD_BLOCK_${action.blockId}_FAILURE`,
          output: { textResponse, imageResponse, linkResponse }
        })
      }
    }
  } catch (e) {
    yield put({ type: `GET_INFO_CARD_BLOCK_${action.blockId}_FAILURE`, output: e })
  }
}

export function * getInfoCardsTitle (action) {
  const Url = `/api/cxf/ratu-infocards-content/card/${action.cardReferenceCode}`
  const localizedContent = yield select(selectors.localizedContent)
  const localizedStrings = localizedContent.localizedStrings
  try {
    const response = yield call(window.fetch, Url, {
      method: 'GET',
      headers: new window.Headers({
        'Content-Type': 'application/json'
      })
    })
    if (response.ok) {
      const jsonResponse = yield response.json()
      document.title = `${jsonResponse.label} - ${localizedStrings.ratuPakki}`
      yield put({ type: 'GET_INFO_CARD_TITLE_SUCCESS', infoCardsTitle: jsonResponse })
    } else {
      if (response.status === 401) {
        yield put({ type: 'GET_INFO_CARD_TITLE_UNAUTHORIZED', output: response })
      } else {
        yield put({ type: 'GET_INFO_CARD_TITLE_FAILURE', output: response })
      }
    }
  } catch (e) {
    yield put({ type: 'GET_INFO_CARD_TITLE_FAILURE', output: e })
  }
}

export function * getCalculatorContent (action) {
  const Url = `/api/cxf/ratu-infocards-content/calculator/card/${action.cardReferenceCode}`
  try {
    const response = yield call(window.fetch, Url, {
      method: 'GET',
      headers: new window.Headers({
        'Content-Type': 'application/json'
      })
    })
    if (response.ok) {
      const jsonResponse = yield response.json()
      yield put({ type: 'GET_CALCULATOR_CONTENT_SUCCESS', calculatorContent: jsonResponse })
    } else {
      if (response.status === 401) {
        yield put({ type: 'GET_CALCULATOR_CONTENT_UNAUTHORIZED', output: response })
      } else {
        yield put({ type: 'GET_CALCULATOR_CONTENT_FAILURE', output: response })
      }
    }
  } catch (e) {
    yield put({ type: 'GET_CALCULATOR_CONTENT_FAILURE', output: e })
  }
}

export function * checkBlockFailures (action) {
  try {
    const infoCardsContent = yield select(selectors.infoCardsContent)
    const { block1Fail, block2Fail, block3Fail, block4Fail } = infoCardsContent
    if (block1Fail && block2Fail && block3Fail && block4Fail) {
      yield put({
        type: 'INFO_CARD_BLOCKS_FAIL_REDIRECT',
        route: '/error/404'
      })
    }
  } catch (e) {
  }
}

function encode (input) {
  const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
  let output = ''
  let chr1, chr2, chr3, enc1, enc2, enc3, enc4
  let i = 0

  while (i < input.length) {
    chr1 = input[i++]
    chr2 = i < input.length ? input[i++] : Number.NaN // Not sure if the index
    chr3 = i < input.length ? input[i++] : Number.NaN // checks are needed here

    enc1 = chr1 >> 2
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
    enc4 = chr3 & 63

    if (isNaN(chr2)) {
      enc3 = enc4 = 64
    } else if (isNaN(chr3)) {
      enc4 = 64
    }
    output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
      keyStr.charAt(enc3) + keyStr.charAt(enc4)
  }
  return output
}

export function * getImage (action) {
  const Url = `/api/cxf/ratu-infocards-content/image/files/${action.filename}`
  try {
    const response = yield call(window.fetch, Url, {
      method: 'GET'
    })
    if (response.ok) {
      const buffer = yield response.arrayBuffer()
      const bytes = yield new Uint8Array(buffer)
      yield put({ type: 'GET_IMAGE_SUCCESS', image: 'data:image/png;base64,' + encode(bytes), filename: action.filename })
    } else {
      if (response.status === 401) {
        yield put({ type: 'GET_IMAGE_UNAUTHORIZED', output: response, filename: action.filename })
      } else {
        yield put({ type: 'GET_IMAGE_FAILURE', output: response, filename: action.filename })
      }
    }
  } catch (e) {
    yield put({ type: 'GET_IMAGE_FAILURE', output: e, filename: action.filename })
  }
}

export function * getBlockTypes (action) {
  const Url = '/api/cxf/ratu-infocards-content/blocktype'
  try {
    const response = yield call(window.fetch, Url, {
      method: 'GET',
      headers: new window.Headers({
        'Content-Type': 'application/json'
      })
    })
    if (response.ok) {
      const jsonResponse = yield response.json()
      yield put({ type: 'GET_BLOCK_TYPES_SUCCESS', blockTypes: jsonResponse })
    } else {
      if (response.status === 401) {
        yield put({ type: 'GET_BLOCK_TYPES_UNAUTHORIZED', output: response })
      } else {
        yield put({ type: 'GET_BLOCK_TYPES_FAILURE', output: response })
      }
    }
  } catch (e) {
    yield put({ type: 'GET_BLOCK_TYPES_FAILURE', output: e })
  }
}
