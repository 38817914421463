import {
  put, call
} from 'redux-saga/effects'

export function * ratuSearch (action) {
  const Url = `/search/cxf/ratu-infocards/card/search?q=${action.searchString}`
  try {
    const response = yield call(window.fetch, Url, {
      method: 'GET',
      headers: new window.Headers({
        'Content-Type': 'application/json'
      })
    })
    if (response.ok) {
      const jsonResponse = yield response.json()
      yield put({ type: 'SEARCH_SUCCESS', searchResults: jsonResponse })
    } else {
      const jsonResponse = yield response.body
      yield put({ type: 'SEARCH_FAILURE', output: jsonResponse })
    }
  } catch (e) {
    yield put({ type: 'SEARCH_FAILURE', output: e })
  }
}
