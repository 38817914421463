import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../../components/Header/Header'
import HeaderImageWithSearch from '../../components/Header/HeaderImageWithSearch'
import Breadcrumbs from '../../components/Navigation/Breadcrumbs'
import Footer from '../../components/Footer/Footer'
import InfoCardsDisplay from '../../components/InfoCards/InfoCardsDisplay'
import {
  getBlockTypes,
  getCalculatorContent,
  getFooterCms,
  getInfoCardBlockContent,
  getInfoCardsTitle
} from '../../actions'
import MenuOpenBackground from '../../components/MenuOpenBackground/MenuOpenBackground'

function InfoCardsPage (props) {
  const localizedContent = useSelector(state => state.localizedContent)
  const {
    footerCmsContent,
    gettingFooterCmsContent
  } = useSelector(state => state.cmsContent)
  const infoCardsContent = useSelector(state => state.infoCardsContent)
  const cardReferenceCode = props.match.params.cardReferenceCode
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getFooterCms())
  }, [])

  useEffect(() => {
    dispatch(getInfoCardsTitle(cardReferenceCode))
    dispatch(getInfoCardBlockContent(cardReferenceCode, 1))
    dispatch(getInfoCardBlockContent(cardReferenceCode, 2))
    dispatch(getInfoCardBlockContent(cardReferenceCode, 3))
    dispatch(getInfoCardBlockContent(cardReferenceCode, 4))
    dispatch(getCalculatorContent(cardReferenceCode))
    dispatch(getBlockTypes())
  }, [props.match.params.cardReferenceCode])

  return (
    <section className='section is-paddingless has-background-grey-lightest'>
      <Header localizedContent={localizedContent} />
      <HeaderImageWithSearch localizedContent={localizedContent} />
      <Breadcrumbs localizedContent={localizedContent} />
      <div className='columns is-centered'>
        <div className='column is-11'>
          <div className='container is-fluid'>
            <InfoCardsDisplay
              localizedContent={localizedContent}
              infoCardsContent={infoCardsContent}
              key={`infoCardsDisplay${cardReferenceCode}`}
            />
          </div>
        </div>
      </div>
      <Footer
        footerCmsContent={footerCmsContent}
        gettingFooterCmsContent={gettingFooterCmsContent}
      />
      <MenuOpenBackground />
    </section>
  )
}

export default InfoCardsPage
