import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'

import 'typeface-roboto'
import './sass/customstyles.scss'
import { Provider } from 'react-redux'
import logger from 'redux-logger'
import { createBrowserHistory } from 'history'
import { routerMiddleware, ConnectedRouter } from 'connected-react-router'

import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import createRootReducer from './reducers'
import Routes from './routes'
import { rootSaga } from './sagas'

export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
let middleware = [routerMiddleware(history), sagaMiddleware]
if (process.env.REACT_APP_REDUX_LOGGER_ENABLED === 'enabled') {
  middleware = [...middleware, logger]
}
const store = createStore(
  createRootReducer(history),
  compose(
    applyMiddleware(...middleware)
  )
)
sagaMiddleware.run(rootSaga)

// Calculates app height that is used in mobile search results
const appHeight = () => document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
window.addEventListener('resize', appHeight)
appHeight()

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  </Provider>, document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
